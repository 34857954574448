<template>
    <div>
        <!-- 头部数据 -->
        <a-card title="仪表盘" style="width: 100%">
            <template #extra>
                <a-range-picker @change="toSearch" :presets="rangePresets"  valueFormat="YYYY-MM-DD" v-model:value="time" />
            </template>
            <div class="index-top-data-wrap">
                <div class="data-wrap" v-has="'外呼统计'">
                    <h2 >外呼统计</h2 >
                    <ul>
                        <li> <span>{{indexData.data.call_no}}</span>  <p>呼叫数</p> </li>
                        <li> <span>{{indexData.data.call_no_through}}</span> <p>接通数</p> </li>
                        <li> <span>{{indexData.data.call_no_rate}}</span> <p>接通率</p> </li>
                        <li> <span>{{indexData.data.call_no_duration_i}}</span> <p>通话时长(分)</p> </li>
                    </ul>
                </div>
                <div class="data-wrap" v-has="'线索统计'">
                    <h2>线索统计</h2>
                    <ul>
                        <li> <span>{{indexData2.data.clue_no}}</span> <p>新增线索</p> </li>
                        <li> <span>{{indexData2.data.clue_no_state1}} <label>{{indexData2.data.clue_no_state1_rate}}</label></span> <p>新增客户</p> </li>
                        <li> <span>{{indexData2.data.clue_no_state2}} <label>{{indexData2.data.clue_no_state2_rate}}</label></span> <p>待回访</p> </li>
                        <li> <span>{{indexData2.data.clue_no_state3}} <label>{{indexData2.data.clue_no_state3_rate}}</label></span> <p>无效</p> </li>
                    </ul>
                </div>
                <div class="data-wrap data-wrap2" v-has="'派单统计'">
                    <h2>派单统计</h2>
                    <ul>
                        <li> <span>{{indexData3.data.custom_no}}</span> <p>派单</p> </li>
                        <li> <span>{{indexData3.data.custom_no_state1}} <label>{{indexData3.data.custom_no_state1_rate}}</label></span> <p>有效率</p> </li>
                        <li> <span>{{indexData3.data.custom_no_state2}} <label>{{indexData3.data.custom_no_state2_rate}}</label></span> <p>未接通率</p> </li>
                        <li> <span>{{indexData3.data.custom_no_state3}} <label>{{indexData3.data.custom_no_state3_rate}}</label></span> <p>重复率</p> </li>
                        <li> <span>{{indexData3.data.custom_no_state4}} <label>{{indexData3.data.custom_no_state4_rate}}</label></span> <p>无效率</p> </li>
                    </ul>
                </div>
            </div>
        </a-card>
       


        <!-- 折线图 -->
        <div class="index-cahrts-wrap">
            <a-card title="外呼统计" v-has="'外呼统计'" :bordered="bordered" >
                <template #extra>
                    <a-range-picker :disabled-date="disabledDate" @change="toSearch2" @calendarChange="onCalendarChange"  valueFormat="YYYY-MM-DD" v-model:value="time2" />
                </template>
                <div id="indexCharts" style="width:100%;height:420px;background:#fff;box-size:border-box;padding-top:20px"></div>
            </a-card>
            <a-card title="线索统计" v-has="'线索统计'" :bordered="bordered" >
                <template #extra>
                    <a-range-picker :disabled-date="disabledDate3" @change="toSearch3" @calendarChange="onCalendarChange3"  valueFormat="YYYY-MM-DD" v-model:value="time3" />
                </template>
                <div id="indexCharts2" style="width:100%;height:420px;background:#fff;box-size:border-box;padding-top:20px"></div>
            </a-card>
            <a-card title="派单统计" v-has="'派单统计'" :bordered="bordered" >
                <template #extra>
                    <a-range-picker :disabled-date="disabledDate4" @change="toSearch4" @calendarChange="onCalendarChange4"  valueFormat="YYYY-MM-DD" v-model:value="time4" />
                </template>
                <div id="indexCharts3" style="width:100%;height:420px;background:#fff;box-size:border-box;padding-top:20px"></div>
            </a-card>
        </div>
        


    </div>
</template>


<script>
import * as echarts from 'echarts';
import { nextTick,reactive,ref } from 'vue'
import { getCurrentInstance } from 'vue'
import moment from "moment";
import dayjs from 'dayjs';
import "dayjs/locale/zh-cn";
export default {
    setup(){
        let bordered=ref(false)

        const { proxy } = getCurrentInstance();

        let time2=ref()
        let time3=ref()
        let time4=ref()

        const rangePresets = ref([
            { label: '今天', value: [dayjs().add(0, 'd'), dayjs()] },
            { label: '昨天', value: [dayjs().add(-1, 'd'), dayjs()] },
            { label: '最近7天', value: [dayjs().add(-7, 'd'), dayjs()] },
            { label: '最近30天', value: [dayjs().add(-30, 'd'), dayjs()] },
        ]);

        const disabledDate = (current) => {
            if (!time2.value || time2.value.length === 0) {
                return false;
            }

            const tooLate = time2.value[0] && dayjs(current).diff(time2.value[0], 'day') > 4;
            const tooEarly = time2.value[1] && dayjs(time2.value[1]).diff(current, 'day') > 4;

            return tooEarly || tooLate;
        }
        const onCalendarChange = (val) => {
         time2.value = val;
        };

        const disabledDate3 = (current) => {
            if (!time3.value || time3.value.length === 0) {
                return false;
            }

            const tooLate = time3.value[0] && dayjs(current).diff(time3.value[0], 'day') > 4;
            const tooEarly = time3.value[1] && dayjs(time3.value[1]).diff(current, 'day') > 4;

            return tooEarly || tooLate;
        }
        const onCalendarChange3 = (val) => {
            time3.value = val;
        };

        const disabledDate4 = (current) => {
            if (!time4.value || time4.value.length === 0) {
                return false;
            }

            const tooLate = time4.value[0] && dayjs(current).diff(time4.value[0], 'day') > 4;
            const tooEarly = time4.value[1] && dayjs(time4.value[1]).diff(current, 'day') > 4;

            return tooEarly || tooLate;
        }
        const onCalendarChange4 = (val) => {
            time4.value = val;
        };

        let toSearch=function(){
            getTableData1()
            getTableData2()
            getTableData3()
        }
        let toSearch2=function(){
            getCharts2()
        }
        let toSearch3=function(){
            getCharts3()
        }
        let toSearch4=function(){
            getCharts4()
        }



         // 折线图
        let getCharts2= function(){
            var date2=""
            if(time2.value){
                date2=moment(time2.value[0]+" 00:00:00").unix()+"-"+moment(time2.value[1]+" 23:59:59").unix()
            }
            proxy.$http.post('/admin/callstatisticslinechart',{create_time:date2},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    let myChart = echarts.init(document.getElementById('indexCharts'));
                    myChart.setOption({
                        legend: {
                            data: ['呼叫数', '接通数','通话时长']
                        },
                        tooltip: {
                            trigger: 'axis'
                        },
                        xAxis: {
                            data: res.data.data.times
                        },
                        yAxis:{},
                        grid: {top:'17%',left:"6%",right:"6%"},
                        series: [
                            {
                                name: "呼叫数",
                                type: "line",
                                smooth: true,
                                itemStyle:{
                                    normal: {
                                        lineStyle: {
                                            width:5,
                                            color:"#409EFC"
                                        }
                                    }
                                },
                                data: res.data.data.data[0].call_nos
                            },
                            {
                                name: "接通数",
                                type: "line",
                                smooth: true,
                                itemStyle:{
                                    normal: {
                                        lineStyle: {
                                            width:5,
                                            color:"#3ea03f"
                                        }
                                    }
                                },
                                data: res.data.data.data[0].call_no_throughs
                            },
                            {
                                name: "通话时长",
                                type: "line",
                                smooth: true,
                                itemStyle:{
                                    normal: {
                                        lineStyle: {
                                            width:5,
                                            color:"#aa54f3"
                                        }
                                    }
                                },
                                data: res.data.data.data[0].call_no_duration_is
                            }
                        ]
                    });
                }else{
                    message.error(res.data.msg);
                }
            })
        }
        getCharts2()
        let getCharts3= function(){

            var date3=""
            if(time3.value){
                date3=moment(time3.value[0]+" 00:00:00").unix()+"-"+moment(time3.value[1]+" 23:59:59").unix()
            }
            proxy.$http.post('/admin/cluestatisticslinechart',{create_time:date3},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    let myChart2 = echarts.init(document.getElementById('indexCharts2'));
                    myChart2.setOption({
                        legend: {
                            data: ['线索', '客户', '待回访', '无效']
                        },
                        tooltip: {
                            trigger: 'axis'
                        },
                        xAxis: {
                            data: res.data.data.times
                        },
                        yAxis:{},
                        grid: {top:'17%',left:"6%",right:"6%"},
                        series: [
                             {
                                name: "线索",
                                type: "line",
                                smooth: true,
                                itemStyle:{
                                    normal: {
                                        lineStyle: {
                                            width:5,
                                            color:"#409EFC"
                                        }
                                    }
                                },
                                data: res.data.data.data[0].clue_nos
                            },
                            {
                                name: "客户",
                                type: "line",
                                smooth: true,
                                itemStyle:{
                                    normal: {
                                        lineStyle: {
                                            width:5,
                                            color:"#3ea03f"
                                        }
                                    }
                                },
                                data: res.data.data.data[0].clue_no_state1s
                            },
                            {
                                name: "待回访",
                                type: "line",
                                smooth: true,
                                itemStyle:{
                                    normal: {
                                        lineStyle: {
                                            width:5,
                                            color:"#aa54f3"
                                        }
                                    }
                                },
                                data: res.data.data.data[0].clue_no_state2s
                            },
                            {
                                name: "无效",
                                type: "line",
                                smooth: true,
                                itemStyle:{
                                    normal: {
                                        lineStyle: {
                                            width:5,
                                            color:"#7c7a7e"
                                        }
                                    }
                                },
                                data: res.data.data.data[0].clue_no_state3s
                            }
                        ]
                    });
                }else{
                    message.error(res.data.msg);
                }
            })

        }
        getCharts3()

        let getCharts4= function(){

            var date4=""
            if(time4.value){
                date4=moment(time4.value[0]+" 00:00:00").unix()+"-"+moment(time4.value[1]+" 23:59:59").unix()
            }
            proxy.$http.post('/admin/customstatisticslinechart',{create_time:date4},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    let myChart3 = echarts.init(document.getElementById('indexCharts3'));
                    myChart3.setOption({
                        legend: {
                            data: ['派单', '有效', '未接通','重单', '无效']
                        },
                        tooltip: {
                            trigger: 'axis'
                        },
                        xAxis: {
                            data: res.data.data.times
                        },
                        yAxis:{},
                        grid: {top:'17%',left:"6%",right:"6%"},
                        series: [
                             {
                                name: "派单",
                                type: "line",
                                smooth: true,
                                itemStyle:{
                                    normal: {
                                        lineStyle: {
                                            width:5,
                                            color:"#409EFC"
                                        }
                                    }
                                },
                                data: res.data.data.data[0].custom_nos
                            },
                            {
                                name: "有效",
                                type: "line",
                                smooth: true,
                                itemStyle:{
                                    normal: {
                                        lineStyle: {
                                            width:5,
                                            color:"#3ea03f"
                                        }
                                    }
                                },
                                data: res.data.data.data[0].custom_no_state1s
                            },
                            {
                                name: "未接通",
                                type: "line",
                                smooth: true,
                                itemStyle:{
                                    normal: {
                                        lineStyle: {
                                            width:5,
                                            color:"#e33854"
                                        }
                                    }
                                },
                                data: res.data.data.data[0].custom_no_state2s
                            },
                            {
                                name: "重单",
                                type: "line",
                                smooth: true,
                                itemStyle:{
                                    normal: {
                                        lineStyle: {
                                            width:5,
                                            color:"#aa54f3"
                                        }
                                    }
                                },
                                data: res.data.data.data[0].custom_no_state3s
                            },
                            {
                                name: "无效",
                                type: "line",
                                smooth: true,
                                itemStyle:{
                                    normal: {
                                        lineStyle: {
                                            width:5,
                                            color:"#7c7a7e"
                                        }
                                    }
                                },
                                data: res.data.data.data[0].custom_no_state4s
                            }
                        ]
                    });
                }else{
                    message.error(res.data.msg);
                }
            })

                    
        }
        getCharts4()
        

        // 头部数据
        let indexData=reactive({data:{}})
        let time=ref()
        let getTableData1=function(){
            var date=""
            if(time.value){
                date=moment(time.value[0]+" 00:00:00").unix()+"-"+moment(time.value[1]+" 23:59:59").unix()
            }
            proxy.$http.post('/admin/callstatistics',{create_time:date},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    indexData.data=res.data.data
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getTableData1()
        let indexData2=reactive({data:{}})
        let getTableData2=function(){
            var date=""
            if(time.value){
                date=moment(time.value[0]+" 00:00:00").unix()+"-"+moment(time.value[1]+" 23:59:59").unix()
            }
            proxy.$http.post('/admin/cluestatistics',{create_time:date},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    indexData2.data=res.data.data
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getTableData2()
        let indexData3=reactive({data:{}})
        let getTableData3=function(){
            var date=""
            if(time.value){
                date=moment(time.value[0]+" 00:00:00").unix()+"-"+moment(time.value[1]+" 23:59:59").unix()
            }
            proxy.$http.post('/admin/customstatistics',{create_time:date},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    indexData3.data=res.data.data
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getTableData3()

        return{
            rangePresets,
            toSearch,toSearch2,toSearch3,toSearch4,
            disabledDate,time2,onCalendarChange,
            disabledDate3,time3,onCalendarChange3,
            disabledDate4,time4,onCalendarChange4,
            indexData,indexData2,indexData3,time,
            bordered
        }
    }
}
</script>