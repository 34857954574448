
<template>
    <div>
        <div class="flexwrap">
            <div class="btn-list">
                <a-button type="primary" @click="openAdd(0)"><template #icon><PlusOutlined /></template>添加机构管理员</a-button>
                <a-button type="primary" danger @click="delMore"><template #icon><DeleteOutlined /></template>删除</a-button>
            </div>
            <a-button class="reloadbtn" @click="reload"><ReloadOutlined /> 刷新</a-button>
        </div>
        <a-divider style="margin:16px 0" />
        <div class="search">
             <div class="left-option">

                <a-input style="width:240px" placeholder="机构管理员姓名" v-model:value="search" />
                <a-button  type="primary" @click="toSearch">查询</a-button>
            </div>
            <div class="table-wrap">

                <a-table row-key="id" size="small" :pagination="false" :columns="columns" :data-source="dataList.arr" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }">
                    <template #bodyCell="{ column,record}">
                        <template v-if="column.dataIndex === 'phone_call_api_type'">
                            <span v-if="record.phone_call_api_type==2">
                                蚁巢网页电话
                            </span>
                            <span v-if="record.phone_call_api_type==1">
                                商机通回呼
                                <span v-if="record.is_bind_sjt==0">（未绑定）</span>
                                <span v-if="record.is_bind_sjt==1">（已绑定）</span>
                            </span>
                            <span v-if="record.phone_call_api_type==3">
                                PBX通话
                                
                            </span>
                            <span v-else></span>
                        </template>
                        <template v-if="column.dataIndex === 'operation'">
                            <a-button type="link" @click="openEdit(record.id)">编辑</a-button>
                            <a-button type="link" @click="del(record.id)">删除</a-button>
                        </template>
                    </template>
                </a-table>

                <a-pagination
                    v-model:pageSize="page.pageSize"
                    show-size-changer
                    :total="page.total"
                    @change="pageChange"
                    :show-total="total => `共 ${total} 条数据`"
                />
            </div>
        </div>

        <!-- 添加、编辑机构管理员 -->
        <a-drawer v-model:visible="visible" :title="title" @ok="handleOk" placement="right" size="large">
             <template #extra>
                <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
                <a-button type="primary" @click="handleOk">提交</a-button>
            </template>
             <a-form
                :model="formData.data"
                :label-col="{ span: 4 }"
                :wrapper-col="{ span: 17 }"
                autocomplete="off"
            >
                <a-form-item label="用户名" name="username" >
                    <a-input placeholder="用户名" v-model:value="formData.data.username" />
                </a-form-item>
                <a-form-item label="手机" name="telephone" >
                    <a-input placeholder="手机" v-model:value="formData.data.telephone" />
                </a-form-item>

                
                <a-form-item label="密码" name="password" >
                    <a-input-password placeholder="密码" v-model:value="formData.data.password" />
                </a-form-item>
                <a-form-item label="确认密码" name="repassword" >
                    <a-input-password placeholder="确认密码" v-model:value="formData.data.repassword" />
                </a-form-item>
                <a-form-item label="所属机构" name="organ_id" >
                    <a-select ref="select" v-model:value="formData.data.organ_id"   >
                        <a-select-option v-for="item in institutionList.arr" :value="item.id" :key="item.id">{{item.title}}</a-select-option>
                    </a-select>
                </a-form-item>
                <!-- <a-form-item label="角色权限" name="admin_role_id" >
                    <a-select ref="select" v-model:value="formData.data.admin_role_id"   >
                        <a-select-option v-for="item in roleList.arr" :value="item.id" :key="item.id">{{item.title}}</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="呼叫类型" name="phone_call_api_type" >
                    <a-select ref="select" v-model:value="formData.data.phone_call_api_type"   >
                         <a-select-option :value="3">PBX通话</a-select-option>
                        <a-select-option :value="2">蚁巢网页电话</a-select-option>
                        <a-select-option :value="1">商机通回呼</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item v-if="formData.data.phone_call_api_type==1" label="绑定坐席" name="admin_role_id" >
                    <a-button v-if="formData.data.is_bind_sjt==0||formData.data.is_bind_sjt==null" type="primary" @click="binding">绑定坐席</a-button>
                    <a-button v-if="formData.data.is_bind_sjt==1" type="primary" @click="toSecure">解除绑定</a-button>
                </a-form-item> -->
            </a-form>
        </a-drawer>
    </div>
</template>

<script>
import { reactive,ref } from 'vue';
import {ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { getCurrentInstance ,createVNode} from 'vue'
import { message,Modal} from 'ant-design-vue';
const columns = [
    {title: 'ID',dataIndex: 'id',width:90},
    {title: '姓名',dataIndex: 'username'},
    {title: '手机号',dataIndex: 'telephone'},
    {title: '所属机构',dataIndex: 'organ_title'},
    {title: '角色',dataIndex: 'title_admin_role'},
    {title: '呼叫类型',dataIndex: 'phone_call_api_type'},
    {title: '添加时间',dataIndex: 'create_time'},
    {title: '操作',dataIndex: 'operation',width:180}
];
export default {
    setup(){
        const { proxy } = getCurrentInstance()
        let dataList=reactive({arr:[{id:"1",title:"机构管理员视频讲堂一",ishow:"1"}]})
        let roleList=reactive({arr:[]})
        let page=reactive({pageSize:"10",total:"20",page:"1"})//分页
        // 角色
         let getRole=function(){
             proxy.$http.post('/admin/rolelist',{pagesize:100},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    roleList.arr=res.data.data.data
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getRole()

        // 机构列表
        let institutionList=reactive({arr:[]})
         let getInstitution=function(){
             proxy.$http.post('/admin/organlist',{pagesize:100},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    institutionList.arr=res.data.data.data
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getInstitution()
        // 呼叫类型
        // let getType=function(){
        //      proxy.$http.post('/admin/rolelist',{pagesize:100},{
        //         headers:{
        //             'token':sessionStorage.getItem("token")
        //         }
        //     }).then((res) => {
        //         if(res.data.status==200){
        //             roleList.arr=res.data.data.data
        //         }else{
        //             message.error(res.data.msg);
        //         }
        //     });
        // }
        // getType()

         // 搜索
        let search=ref()
        let toSearch=function(){
            page.page="1"
            getTabledata()
        }

         // 列表
         let getTabledata=function(){
            proxy.$http.post('/admin/adminuserlist',{page:page.page,pagesize:page.pageSize,username:search.value,admin_type_id:4},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    dataList.arr=res.data.data.data
                    page.total=res.data.data.total
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getTabledata()

        // 添加、编辑
        let formData=reactive({data:{username:"",telephone:"",password:"",repassword:"",admin_role_id:"",organ_id:"",phone_call_api_type:""}})
        let title=ref("")
        let visible=ref(false)
        let openAdd=function(){
            formData.data.organ_id=""
            formData.data.username=""
            formData.data.telephone=""
            formData.data.password=""
            formData.data.repassword=""
            formData.data.admin_role_id=""
            formData.data.phone_call_api_type=""
            title.value="添加机构管理员"
            visible.value=true
        }

        let openEdit=function(id){
            proxy.$http.post('/admin/adminuserpage',{id:id},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    visible.value=true
                    title.value="编辑机构管理员"
                    formData.data=res.data.data.data
                    if(res.data.data.data.organ_id){
                        formData.data.organ_id=parseInt(res.data.data.data.organ_id)
                    }
                    
                    formData.data.password=""
                    formData.data.repassword=""
                }else{
                    message.error(res.data.msg);
                }
            });
        }

        let handleOk=function(){
            if(formData.data.password!=formData.data.repassword){
                message.error("两次密码不一致！");
            }else{
                if(title.value=="添加机构管理员"){
                    proxy.$http.post('/admin/addadminuser',{pid:sessionStorage.getItem("pid"),username:formData.data.username,telephone:formData.data.telephone,
                    password:formData.data.password,admin_role_id:"2",admin_type_id:"4",phone_call_api_type:formData.data.phone_call_api_type,organ_id:formData.data.organ_id},{
                        headers:{
                            'token':sessionStorage.getItem("token")
                        }
                    }).then((res) => {
                        if(res.data.status==200){
                            visible.value=false
                            message.success(res.data.msg)
                            getTabledata()
                        }else{
                            message.error(res.data.msg);
                        }
                    });
                }else if(title.value=="编辑机构管理员"){
                    proxy.$http.post('/admin/updateadminuser',{id:formData.data.id,pid:sessionStorage.getItem("pid"),username:formData.data.username,telephone:formData.data.telephone,
                    password:formData.data.password,admin_role_id:"2",admin_type_id:"4",phone_call_api_type:formData.data.phone_call_api_type,organ_id:formData.data.organ_id},{
                        headers:{
                            'token':sessionStorage.getItem("token")
                        }
                    }).then((res) => {
                        if(res.data.status==200){
                            visible.value=false
                            message.success(res.data.msg)
                            getTabledata()
                        }else{
                            message.error(res.data.msg);
                        }
                    });
                }
            }
            
        }

         // 绑定
        let binding=function(){
            proxy.$http.post('/admin/addseatssjt',{admin_user_id:formData.data.id},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    message.success(res.data.msg);
                }else{
                    message.error(res.data.msg);
                }
            });
        }

        // 解除绑定
        let toSecure=function(){
            proxy.$http.post('/admin/delseatssjt',{admin_user_id:formData.data.id},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    message.success(res.data.msg);
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        // 多选
        let selectedRowKeys=ref()
        let onSelectChange=function(selected) {
            selectedRowKeys.value = selected;
        }

          // 删除
        let delMore=function(){
            var id=selectedRowKeys.value.join(",")
            del(id)
        }
        let del=function(id){
            Modal.confirm({
                title: '你确定要删除吗？',
                icon: createVNode(ExclamationCircleOutlined),
            onOk() {
                proxy.$http.post('/admin/deladminuser',{ids:id},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            },
            onCancel() {
             console.log('Cancel');
            }
            })
            
        }

        let onClose=function(){
            visible.value = false;
        }


        // 分页
        let pageChange=function(current, size){
            page.pageSize=size
            page.page=current
            getTabledata()
        }
        let reload=function(){
            getTabledata()
        }
        return{
            reload,
            binding,toSecure,onClose,
            toSearch,search,
            visible,formData,openAdd,title,openEdit,handleOk,roleList,institutionList,
            onSelectChange,delMore,del,
            columns,dataList,pageChange,page
        }
    }
}
</script>