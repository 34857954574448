
<template>
    <div>
        <div class="flexwrap">
            <div class="btn-list">
                <a-button type="primary" @click="openAdd(0)"><template #icon><PlusOutlined /></template>添加权限</a-button>
                <a-button type="primary" danger @click="delMore"><template #icon><DeleteOutlined /></template>删除</a-button>
            </div>
            <a-button class="reloadbtn" @click="reload"><ReloadOutlined /> 刷新</a-button>
        </div>
        <a-divider style="margin:16px 0" />
        <div class="search">
             <div class="left-option">

                <a-input style="width:240px" placeholder="权限名称" v-model:value="search.title" />
                <a-tree-select
                    style="width: 160px;margin-left:14px"
                    v-model:value="search.node_id"
                    show-search
                    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                    placeholder="节点"
                    allow-clear
                    tree-default-expand-all
                    :tree-data="nodeList.arr"
                    :field-names="{label: 'title',value: 'id'}"
                >
                </a-tree-select>
                <a-button  type="primary" @click="toSearch">查询</a-button>
            </div>
            <div class="table-wrap">

                <a-table size="small" row-key="id" :pagination="false" :columns="columns" :data-source="dataList.arr" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }">
                    <template #bodyCell="{ column,record}">
                        <template v-if="column.dataIndex === 'state'">
                            <a-switch @change="checked => changeStatus(checked, record.id)" v-model:checked="record.state" :checkedValue="1" :unCheckedValue="0" checked-children="正常" un-checked-children="禁用" />
                        </template>
                        <template v-if="column.dataIndex === 'operation'">
                            <a-button type="link" @click="openEdit(record.id)">编辑</a-button>
                            <a-button type="link" @click="del(record.id)">删除</a-button>
                        </template>
                    </template>
                </a-table>

                <a-pagination
                    v-model:pageSize="page.pageSize"
                    show-size-changer
                    :total="page.total"
                    @change="pageChange"
                    :show-total="total => `共 ${total} 条数据`"
                />
            </div>
        </div>

        <!-- 添加、编辑权限 -->
        <a-modal v-model:visible="visible" :title="title" @ok="handleOk" >
             <a-form
                :model="formData.data"
                :label-col="{ span: 4 }"
                :wrapper-col="{ span: 17 }"
                autocomplete="off"
            >
                <a-form-item label="节点" name="username" >
                    <a-tree-select
                    v-model:value="formData.data.node_id"
                    show-search
                    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                    placeholder="节点"
                    allow-clear
                    tree-default-expand-all
                    :tree-data="nodeList.arr"
                    :field-names="{label: 'title',value: 'id'}"
                    >
                    </a-tree-select>
                </a-form-item>
                <a-form-item label="权限名称" name="title" >
                    <a-input placeholder="权限名称" v-model:value="formData.data.title" />
                </a-form-item>

                
                <a-form-item label="标签" name="password" >
                    <a-input placeholder="标签" v-model:value="formData.data.tag" />
                </a-form-item>
                <a-form-item label="状态" name="repassword" >
                    <a-switch v-model:checked="formData.data.state" :checkedValue="1" :unCheckedValue="0" checked-children="正常" un-checked-children="禁用" />
                </a-form-item>
                <a-form-item label="排序" name="sort" >
                     <a-input placeholder="排序" v-model:value="formData.data.sort" />
                </a-form-item>
            </a-form>
        </a-modal>
    </div>
</template>

<script>
import { reactive,ref } from 'vue';
import {ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { getCurrentInstance ,createVNode} from 'vue'
import { message,Modal} from 'ant-design-vue';
const columns = [
    {title: 'ID',dataIndex: 'id',width:90},
    {title: '节点',dataIndex: 'title_node'},
    {title: '权限名称',dataIndex: 'title'},
    {title: '标签',dataIndex: 'tag'},
    {title: '状态',dataIndex: 'state'},
    {title: '排序',dataIndex: 'sort'},
    {title: '操作',dataIndex: 'operation',width:180}
];
export default {
    setup(){
        const { proxy } = getCurrentInstance()
        let dataList=reactive({arr:[{id:"1",title:"权限视频讲堂一",ishow:"1"}]})
        let roleList=reactive({arr:[]})
        let page=reactive({pageSize:"10",total:"20",page:"1"})//分页

        // 节点列表
        // 列表
        let nodeList=reactive({arr:[]})
        let getNode=function(){
            proxy.$http.post('/admin/nodelist',{},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    nodeList.arr=res.data.data

                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getNode()

         // 搜索
        let search=reactive({node_id:undefined,title:""})
        let toSearch=function(){
            page.page="1"
            getTabledata()
        }

         // 列表
         let getTabledata=function(){
            proxy.$http.post('/admin/nodeauthlist',{page:page.page,pagesize:page.pageSize,node_id:search.node_id,title:search.title},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    dataList.arr=res.data.data.data
                    page.total=res.data.data.total
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getTabledata()

        // 添加、编辑
        let formData=reactive({data:{node_id:"",title:"",tag:"",state:1,sort:"",}})
        let title=ref("")
        let visible=ref(false)
        let openAdd=function(){
            formData.data.node_id=""
            formData.data.title=""
            formData.data.tag=""
            formData.data.state=1
            formData.data.sort=""
            title.value="添加权限"
            visible.value=true
        }

        let openEdit=function(id){
            proxy.$http.post('/admin/nodeauthpage',{id:id},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    visible.value=true
                    formData.data=res.data.data.data
                    title.value="编辑权限"
                }else{
                    message.error(res.data.msg);
                }
            });
        }

        let handleOk=function(){

            if(title.value=="添加权限"){
                proxy.$http.post('/admin/addnodeauth',{node_id:formData.data.node_id,title:formData.data.title,
                tag:formData.data.tag,state:formData.data.state,sort:formData.data.sort},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        visible.value=false
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            }else if(title.value=="编辑权限"){
                proxy.$http.post('/admin/updatenodeauth',{id:formData.data.id,node_id:formData.data.node_id,title:formData.data.title,
                tag:formData.data.tag,state:formData.data.state,sort:formData.data.sort},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        visible.value=false
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            }

            
        }
        // 多选
        let selectedRowKeys=ref()
        let onSelectChange=function(selected) {
            selectedRowKeys.value = selected;
        }

        let changeStatus=function(checked,id){

            proxy.$http.post('/admin/updatenodeauth',{id:id,state:checked},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    message.success(res.data.msg)
                    getTabledata()
                }else{
                    message.error(res.data.msg);
                }
            });
        }

          // 删除
        let delMore=function(){
            var id=selectedRowKeys.value.join(",")
            del(id)
        }
        let del=function(id){
            Modal.confirm({
                title: '你确定要删除吗？',
                icon: createVNode(ExclamationCircleOutlined),
            onOk() {
                proxy.$http.post('/admin/delnodeauth',{ids:id},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            },
            onCancel() {
             console.log('Cancel');
            }
            })
            
        }



        // 分页
        let pageChange=function(current, size){
            page.pageSize=size
            page.page=current
            getTabledata()
        }
        let reload=function(){
            getTabledata()
        }
        return{
            reload,
            nodeList,
            toSearch,search,
            visible,formData,openAdd,title,openEdit,handleOk,roleList,changeStatus,
            onSelectChange,delMore,del,
            columns,dataList,pageChange,page
        }
    }
}
</script>