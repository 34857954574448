<template>
    <div >
        <!-- class="fpmenutabs" type="card" -->
        <!-- {{istab}} -->
        <a-tabs  class="fpmenutabs2" @change="changtab">
            <a-tab-pane v-if="tab1" key="11" tab="外呼统计">
                <div class="flexwrap">
                    <div class="analysis-top">
                        <span>拨打线索：{{total1.data.call_no}}</span>
                        <a-divider type="vertical" />
                        <span>接通数：{{total1.data.call_no_through}}</span>
                        <a-divider type="vertical" />
                        <span>接通率：{{total1.data.call_no_rate}}</span>
                        <a-divider type="vertical" />
                        <span>接通时长(分)：{{total1.data.call_no_duration_i}}</span>
                    </div>
                    <a-button style="margin-top:0" class="reloadbtn" @click="reload1"><ReloadOutlined /> 刷新</a-button>
                </div>
                <a-divider style="margin:16px 0" />
                <div class="search">
                    <div class="left-option">
                    <a-range-picker :presets="rangePresets"  valueFormat="YYYY-MM-DD" v-model:value="searchLlist.date" />

                        <a-select
                            ref="select" v-model:value="searchLlist.admin_user_id" style="width: 120px;margin-left:14px"
                            placeholder="呼叫人"
                            allowClear
                        >
                            <a-select-option v-for="item in userList.arr" :value="item.id" :key="item.id">{{item.username}}</a-select-option>
                        </a-select>

                        <!--  <a-select ref="select" v-model:value="searchLlist.jg" style="width: 120px;margin-left:14px"
                            placeholder="机构"
                            allowClear
                        >
                            <a-select-option v-for="item in institutionList.arr" :value="item.id" :key="item.id">{{item.title}}</a-select-option>
                        </a-select>
                        <a-select ref="select" v-model:value="searchLlist.verify_state" style="width: 120px;margin-left:14px"
                            placeholder="核实状态"
                            allowClear
                        >
                            <a-select-option value="0">待核实</a-select-option>
                            <a-select-option value="1">有效</a-select-option>
                            <a-select-option value="2">无效</a-select-option>
                        </a-select>

                        <a-input style="width:90px;margin-left:14px" placeholder="客户姓名" v-model:value="searchLlist.username" /> -->
                        <!-- <a-input style="width:120px;margin-left:14px" placeholder="线索号码" v-model:value="searchLlist.phone" /> -->
                        <!-- <a-select v-if="istab" ref="select" placeholder="线索状态" style="width: 120px;margin-left:14px"  v-model:value="searchLlist.clue_state"   allowClear>
                            <a-select-option v-for="item in cluestatuslist.arr" :value="item.id" :key="item.id">{{item.title}}</a-select-option>
                        </a-select> -->
                        <a-button @click="toSearch" type="primary">查询</a-button>
                    </div>
                    <div class="table-wrap">
                        <!-- {{}} -->
                        <a-table  row-key="id" :pagination="false" :columns="columns" :data-source="dataList.arr" size="small"  >
                            
                            <template #bodyCell="{ column,record}">
                                
                                <template v-if="column.dataIndex === 'file_url'">
                                    <span v-if="record.file_url">
                                        <audio controls :src="record.file_url"></audio>
                                    </span>
                                    <span v-else></span>
                                </template>

                                <template v-if="column.dataIndex === 'call_type'">
                                    <a-tag v-if="record.call_second>0" color="green">已接通</a-tag>
                                    <a-tag v-else >未接通</a-tag>
                                </template>


                                <template v-if="column.dataIndex === 'call_start_time'">
                                    <span v-if="record.call_start_time!=0">{{ changeFormdate(record.call_start_time) }}</span>
                                    <span v-else></span>
                                </template>

                                
                            </template>
                        </a-table>
                        <a-pagination
                            v-model:pageSize="page.pageSize"
                            show-size-changer
                            :total="page.total"
                            @change="pageChange"
                            :show-total="total => `共 ${total} 条数据`"
                        />
                    </div>

                </div>
            </a-tab-pane>
            <a-tab-pane v-if="tab2" key="12" tab="线索统计">
                <div class="flexwrap">
                    <div class="analysis-top">
                        <span>拨打线索：{{total2.data.clue_no}}</span>
                        <a-divider type="vertical" />
                        <span>新增客户：{{total2.data.clue_no_state1}}</span>
                        <a-divider type="vertical" />
                        <span>待回访：{{total2.data.clue_no_state2}}</span>
                        <a-divider type="vertical" />
                        <span>无效：{{total2.data.clue_no_state3}}</span>
                    </div>
                    <a-button style="margin-top:0" class="reloadbtn" @click="reload2"><ReloadOutlined /> 刷新</a-button>
                </div>
                <a-divider style="margin:16px 0" />
                 <div class="search">
                    <div class="left-option">
                    <a-range-picker :presets="rangePresets"  valueFormat="YYYY-MM-DD" v-model:value="searchLlist2.date" />

                        <a-select
                            ref="select" v-model:value="searchLlist2.admin_user_id" style="width: 120px;margin-left:14px"
                            placeholder="所属人"
                            allowClear
                        >
                            <a-select-option v-for="item in userList.arr" :value="item.id" :key="item.id">{{item.username}}</a-select-option>
                        </a-select>
                        <a-button @click="toSearch2" type="primary">查询</a-button>
                    </div>
                    <div class="table-wrap">
                        <!-- {{}} -->
                        <a-table  row-key="id" :scroll="{ x: 2340 }" :pagination="false" :columns="columns2" :data-source="dataList2.arr" size="small"  >
                            
                            <template #bodyCell="{ column,record}">
                                
                                <template v-if="column.dataIndex === 'clue_state'">
                                    <a-tag v-if="record.clue_state==0" color="blue">待确认</a-tag>
                                    <a-tag v-if="record.clue_state==1" color="green">客户</a-tag>
                                    <a-tag v-if="record.clue_state==2" color="purple">待回访</a-tag>
                                    <a-tag v-if="record.clue_state==3" >无效</a-tag>
                                </template>

                                <template v-if="column.dataIndex === 'call_second'">
                                    <a-tag v-if="record.call_second>0" color="green">已接通</a-tag>
                                    <a-tag v-else >未接通</a-tag>
                                </template>

                                <template v-if="column.dataIndex === 'sex'">
                                    <span v-if="record.sex==1">男</span>
                                    <span v-if="record.sex==2">女</span>
                                </template>

                                <template v-if="column.dataIndex === 'clue_tags_arr'">
                                    <a-tag v-for="item in record.clue_tags_arr" :key="item.id" color="blue">{{item.title}}</a-tag>
                                </template>

                                
                            </template>
                        </a-table>
                        <a-pagination
                            v-model:pageSize="page2.pageSize"
                            show-size-changer
                            :total="page2.total"
                            @change="pageChange2"
                            :show-total="total => `共 ${total} 条数据`"
                        />
                    </div>

                </div>
            </a-tab-pane>
            <a-tab-pane v-if="tab3" key="13" tab="派单统计">
                <div class="flexwrap">
                    <div class="analysis-top">
                        <span>新增派单：{{total3.data.custom_no}}</span>
                        <a-divider type="vertical" />
                        <span>有效派单：{{total3.data.custom_no_state1}}</span>
                        <a-divider type="vertical" />
                        <span>未接通：{{total3.data.custom_no_state2}}</span>
                        <a-divider type="vertical" />
                        <span>重复派单：{{total3.data.custom_no_state3}}</span>
                        <a-divider type="vertical" />
                        <span>无效派单：{{total3.data.custom_no_state4}}</span>
                    </div>
                    <a-button style="margin-top:0" class="reloadbtn" @click="reload3"><ReloadOutlined /> 刷新</a-button>
                </div>
                <a-divider style="margin:16px 0" />
                 <div class="search">
                    <div class="left-option">
                    <a-range-picker :presets="rangePresets"  valueFormat="YYYY-MM-DD" v-model:value="searchLlist3.date" />

                        <a-select
                            ref="select" v-model:value="searchLlist3.admin_user_id" style="width: 120px;margin-left:14px"
                            placeholder="所属人"
                            allowClear
                        >
                            <a-select-option v-for="item in userList.arr" :value="item.id" :key="item.id">{{item.username}}</a-select-option>
                        </a-select>
                        <a-button @click="toSearch3" type="primary">查询</a-button>
                    </div>
                    <div class="table-wrap">
                        <!-- {{}} -->
                        <a-table  row-key="id" :scroll="{ x: 2380 }" :pagination="false" :columns="columns3" :data-source="dataList3.arr" size="small"  >
                            
                            <template #bodyCell="{ column,record}">
                                
                               <template v-if="column.dataIndex === 'file_url'">
                                    <span v-if="record.file_url">
                                        <audio controls :src="record.file_url"></audio>
                                    </span>
                                    <span v-else></span>
                                </template>

                                 <template v-if="column.dataIndex === 'sex'">
                                    <span v-if="record.sex==1">男</span>
                                    <span v-if="record.sex==2">女</span>
                                </template>

                                <template v-if="column.dataIndex === 'state'">
                                    <a-tag v-if="record.state==0" color="blue">待确认</a-tag>
                                    <a-tag v-if="record.state==1" color="green">有效</a-tag>
                                    <a-tag v-if="record.state==2" color="red">未接通</a-tag>
                                    <a-tag v-if="record.state==3" color="purple">重单</a-tag>
                                    <a-tag v-if="record.state==4" >无效</a-tag>
                                </template>
                                <template v-if="column.dataIndex === 'verify_state'">
                                    <a-tag v-if="record.verify_state==0" color="blue">待核实</a-tag>
                                    <a-tag v-if="record.verify_state==1" color="green">核实有效</a-tag>
                                    <a-tag v-if="record.verify_state==2" color="red">核实无效</a-tag>
                                </template>

                                <template v-if="column.dataIndex === 'update_time'">
                                    <span v-if="record.update_time!=0">{{ changeFormdate(record.update_time) }}</span>
                                    <span v-else></span>
                                </template>

                                
                            </template>
                        </a-table>
                        <a-pagination
                            v-model:pageSize="page3.pageSize"
                            show-size-changer
                            :total="page3.total"
                            @change="pageChange3"
                            :show-total="total => `共 ${total} 条数据`"
                        />
                    </div>

                </div>
            </a-tab-pane>
        </a-tabs>

        
    </div>
</template>

<script>
const columns = [
    { title: 'ID', dataIndex: 'id' ,width:90},
    {title: '主叫号码',dataIndex: 'call_number'},
    {title: '被叫号码',dataIndex: 'called_number'},
    {title: '通话时长',dataIndex: 'call_second'},
    {title: '通话录音',dataIndex: 'file_url',width:240},
    {title: '呼叫人',dataIndex: 'username_admin_user'},
    {title: '呼叫类型',dataIndex: 'phone_call_api_type_title'},
    {title: '通话状态',dataIndex: 'call_type'},
    {title: '失败原因',dataIndex: 'hangup_cause_title',width:180},
    {title: '呼叫单价',dataIndex: 'call_unit_price_per_minute',width:100},
    {title: '价格',dataIndex: 'price',width:90},
    {title: '呼叫时间',dataIndex: 'call_start_time',width:170}

];
const columns2 = [
  { title: 'ID', dataIndex: 'id' ,width:90},
    { title: '归属人', dataIndex: 'username_belong_admin_user',width:90 },
  { title: '所属项目', dataIndex: 'title_clue_project',width:130 },
   { title: '所属机构', dataIndex: 'title_organ',width:130 },
  { title: '姓名', dataIndex: 'username' ,width:90},
  { title: '性别', dataIndex: 'sex' ,width:70 },
  { title: '年龄', dataIndex: 'age' ,width:70 },
  { title: '手机号码', dataIndex: 'phone' ,width:130},
  { title: '地址', dataIndex: 'address' ,width:130},
  { title: '顾客详情', dataIndex: 'customer_details',width:360 },
  { title: '线索状态', dataIndex: 'clue_state' ,width:90},
  { title: '线索标签', dataIndex: 'clue_tags_arr',width:120 },
  { title: '广告名称', dataIndex: 'ad_project_name' ,width:120},
  { title: '广告计划', dataIndex: 'ad_plan_name'  ,width:120},
  { title: '广告来源', dataIndex: 'ad_source_name'  ,width:120},
  { title: '更新时间', dataIndex: 'update_time' ,width:160},
  { title: '添加时间', dataIndex: 'create_time' ,width:160},


];
const columns3 = [
  { title: 'ID', dataIndex: 'id' ,width:90},
    { title: '归属人', dataIndex: 'username_belong_admin_user',width:90 },
  { title: '所属机构', dataIndex: 'organ_title',width:130 },
  { title: '姓名', dataIndex: 'username' ,width:90},
  { title: '性别', dataIndex: 'sex' ,width:70},
  { title: '年龄', dataIndex: 'age' ,width:70},
  { title: '手机号码', dataIndex: 'phone' ,width:130},
  { title: '地址', dataIndex: 'address' ,width:130},
  { title: '顾客详情', dataIndex: 'customer_details',width:360 },
  { title: '线索状态', dataIndex: 'state' ,width:90,key:"state"},
  { title: '凭证', dataIndex: 'voucher' ,width:110},
  { title: '机构备注', dataIndex: 'organ_remarks' ,width:360 },
  
  { title: '核实状态', dataIndex: 'verify_state' ,width:90 },
  { title: '广告名称', dataIndex: 'ad_project_name' ,width:110},
  { title: '广告计划', dataIndex: 'ad_plan_name' ,width:110},
  { title: '广告来源', dataIndex: 'ad_source_name' ,width:110},
  { title: '更新时间', dataIndex: 'update_time' ,width:120},
  { title: '添加时间', dataIndex: 'create_time',width:120 },

];

import { reactive ,ref} from '@vue/reactivity'
import { getCurrentInstance, computed } from 'vue'
import { message,Modal} from 'ant-design-vue';
import dayjs, { Dayjs } from 'dayjs';
import {useRouter} from "vue-router";
import moment from "moment";
import { formatDate } from "../../common/filter.js";
import "dayjs/locale/zh-cn";
export default {
    data(){
        return{
            noClick: true
        }
    },
    setup(){
         const changeFormdate = computed(() => {
            return (time) => {
                let date = new Date(time);
                return formatDate(date);
            };
        });

        let tab1=ref(false)
        let tab2=ref(false)
        let tab3=ref(false)
        let btnlist=JSON.parse(sessionStorage.getItem('btnlist'))
        for(var i in btnlist){
            if(btnlist[i].title=="外呼统计"){
                tab1.value=true
            }
            if(btnlist[i].title=="线索统计"){
                tab2.value=true
            }
            if(btnlist[i].title=="派单统计"){
                tab3.value=true
            }
        }

        const router=useRouter()
        let searchLlist=reactive({date:"",admin_user_id:undefined})
        let searchLlist2=reactive({date:"",admin_user_id:undefined})
        let searchLlist3=reactive({date:"",admin_user_id:undefined})
        const { proxy } = getCurrentInstance()
        let dataList=reactive({arr:[]})
        let dataList2=reactive({arr:[]})
        let dataList3=reactive({arr:[]})
        const rangePresets = ref([
            { label: '今天', value: [dayjs().add(0, 'd'), dayjs()] },
            { label: '昨天', value: [dayjs().add(-1, 'd'), dayjs()] },
            { label: '最近7天', value: [dayjs().add(-7, 'd'), dayjs()] },
            { label: '最近30天', value: [dayjs().add(-30, 'd'), dayjs()] },
        ]);

        // let changtab=function(){
           
        //     getTabledata()
        // }

        // 客服列表
        let userList=reactive({arr:[]})
        let getUserlist=function(){
            proxy.$http.post('/admin/adminuserlist',{pagesize:500},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    userList.arr=res.data.data.data
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getUserlist()

        // 搜索
        let toSearch=function(){
            page.page="1"
            getTabledata()
        }
        let toSearch2=function(){
            page2.page="1"
            getTabledata2()
        }
        let toSearch3=function(){
            page3.page="1"
            getTabledata3()
        }


        let page=reactive({page:"",pageSize:10,total:20})//分页
        let total1=reactive({data:{}})
        let getTabledata=function(){
            var date=""
            if(searchLlist.date){
                date=moment(searchLlist.date[0]+" 00:00:00").unix()+"-"+moment(searchLlist.date[1]+" 23:59:59").unix()
            }
            
            proxy.$http.post('/admin/callstatisticslist',{page:page.page,pagesize:page.pageSize,create_time:date,
            admin_user_id:searchLlist.admin_user_id
            },{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    dataList.arr=res.data.data.data
                    total1.data=res.data.data.data_statistics
                    page.total=res.data.data.total
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getTabledata()

        let page2=reactive({page:"",pageSize:10,total:20})//分页
        let total2=reactive({data:{}})
        let getTabledata2=function(){
            var date=""
            if(searchLlist2.date){
                date=moment(searchLlist2.date[0]+" 00:00:00").unix()+"-"+moment(searchLlist2.date[1]+" 23:59:59").unix()
            }
            proxy.$http.post('/admin/cluestatisticslist',{page:page2.page,pagesize:page2.pageSize,create_time:date,
            admin_user_id:searchLlist2.admin_user_id
            },{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    dataList2.arr=res.data.data.data
                    total2.data=res.data.data.data_statistics
                    page2.total=res.data.data.total
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getTabledata2()

        let page3=reactive({page:"",pageSize:10,total:20})//分页
        let total3=reactive({data:{}})
        let getTabledata3=function(){
            var date=""
            if(searchLlist3.date){
                date=moment(searchLlist3.date[0]+" 00:00:00").unix()+"-"+moment(searchLlist3.date[1]+" 23:59:59").unix()
            }
        
            proxy.$http.post('/admin/customstatisticslist',{page:page3.page,pagesize:page3.pageSize,create_time:date,
            admin_user_id:searchLlist3.admin_user_id
            },{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    dataList3.arr=res.data.data.data
                    total3.data=res.data.data.data_statistics
                    page3.total=res.data.data.total
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getTabledata3()




        // 分页
        let pageChange=function(current, size){
            page.pageSize=size
            page.page=current
            getTabledata()
        }
        let pageChange2=function(current, size){
            page2.pageSize=size
            page2.page=current
            getTabledata2()
        }
        let pageChange3=function(current, size){
            page3.pageSize=size
            page3.page=current
            getTabledata3()
        }
        let reload1=function(){
            getTabledata()
        }
        let reload2=function(){
            getTabledata2()
        }
        let reload3=function(){
            getTabledata3()
        }
        return{
            reload1,reload2,reload3,
            tab1,tab2,tab3,
            total1,total2,total3,
            userList,changeFormdate,
            toSearch,toSearch2,toSearch3,
            pageChange,getTabledata,dataList,page,rangePresets,
            pageChange2,getTabledata2,dataList2,page2,
            pageChange3,getTabledata3,dataList3,page3,
            searchLlist,columns,searchLlist2,columns2,searchLlist3,columns3
        }
    }
}
</script>