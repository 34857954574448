<template>
    <div>
        <!-- <div class="btn-list">
            <a-button type="primary" @click="openAdd"><template #icon><PlusOutlined /></template>添加权限</a-button>
            <a-button type="primary" danger @click="delMore"><template #icon><DeleteOutlined /></template>删除</a-button>
        </div> -->
         <a-divider style="margin:16px 0" />
         <div class="search">
            <div class="left-option flexwrap2" >
                <div  class="left-option" style="width:100%">
                    <a-range-picker :presets="rangePresets" valueFormat="YYYY-MM-DD"  v-model:value="searchLlist.date" />
                    <a-input style="width:120px;margin-left:14px" placeholder="被叫号码" v-model:value="searchLlist.called" />
                    <a-select
                        ref="select" v-model:value="searchLlist.seat" style="width: 120px;margin-left:14px"
                        placeholder="客服"
                        allowClear
                    >
                        <a-select-option v-for="item in kefuList.arr" :value="item.id" :key="item.id">{{item.username}}</a-select-option>
                    </a-select>
                    <div class="two-input">
                        <a-input type="number" placeholder="通话时长" v-model:value="searchLlist.starttime" />
                        <span>-</span>
                        <a-input type="number"  placeholder="通话时长" v-model:value="searchLlist.end" />
                    </div>



                    <a-button  type="primary" @click="toSearch">查询</a-button>
                </div>
                <a-button style="margin-top:0" class="reloadbtn" @click="reload"><ReloadOutlined /> 刷新</a-button>
            </div>

            <div class="table-wrap">
                <a-table size="small" row-key="id" :pagination="false" :columns="columns" :data-source="dataList.arr" :row-selection="{ selectedRowkeys: selectedRowkeys, onChange: onSelectChange }">
                    <template #bodyCell="{ column,record}">
                        <template v-if="column.dataIndex === 'phone_call_api_type'">
                             <span v-if="record.phone_call_api_type==2">
                                蚁巢网页电话
                            </span>
                            <span v-if="record.phone_call_api_type==1">
                                商机通回呼
                            </span>
                            <span v-if="record.phone_call_api_type==3">
                                PBX通话
                                
                            </span>
                            <span v-else></span>
                            <!-- <span v-if="record.phone_call_api_type==2">
                                蚁巢网页电话
                            </span>
                            <span v-else></span> -->
                        </template>
                        <template v-if="column.dataIndex === 'type'">
                            <a-tag v-if="record.call_second>0" color="green">已接通</a-tag>
                            <a-tag v-else >未接通</a-tag>
                        </template>
                        <template v-if="column.dataIndex === 'call_start_time'">
                            <span v-if="record.call_start_time!=0">{{ changeFormdate(record.call_start_time) }}</span>
                            <span v-else></span>
                        </template>
                        <template v-if="column.dataIndex === 'file_url'">
                            <span v-if="record.file_url">
                                <audio controls :src="record.file_url"></audio>
                            </span>
                            <span v-else></span>
                        </template>
                        <!-- <template v-if="column.dataIndex === 'operation'">
                            <a-button type="link" @click="openEdit(record.id)">详情</a-button>
                        </template> -->
                    </template>
                </a-table>
                <a-pagination
                    v-model:pageSize="page.pageSize"
                    show-size-changer
                    :total="page.total"
                    @change="pageChange"
                    :show-total="total => `共 ${total} 条数据`"
                />
            </div>
        </div>
    </div>

</template>

<script>
import { reactive,ref,computed } from 'vue';
import {ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { getCurrentInstance ,createVNode} from 'vue'
import { message,Modal} from 'ant-design-vue';
import dayjs, { Dayjs } from 'dayjs';
import { formatDate } from "../../common/filter.js";
import moment from "moment";
const columns = [
    {title: 'ID',dataIndex: 'id',width:90},
    {title: '呼叫时间',dataIndex: 'call_start_time'},
    {title: '坐席账号',dataIndex: 'username_admin_user'},
    {title: '主叫号码',dataIndex: 'call_number'},
    {title: '被叫号码',dataIndex: 'called_number'},
    {title: '通话状态',dataIndex: 'type'},
    {title: '通话时长',dataIndex: 'call_second'},
    {title: '通话录音',dataIndex: 'file_url'},
    
    {title: '呼叫类型',dataIndex: 'phone_call_api_type'},
    
];
   
export default {
    
    setup(){
         const changeFormdate = computed(() => {
            return (time) => {
                let date = new Date(time);
                return formatDate(date);
            };
        });
        const { proxy } = getCurrentInstance()
        let dataList=reactive({arr:[]})
        let searchLlist=reactive({date:"",seat:undefined,jg:undefined,starttime:"",endtime:"",called:""})
        
        let page=reactive({page:"",pageSize:"10",total:"20"})//分页

        const rangePresets = ref([
            { label: '今天', value: [dayjs().add(0, 'd'), dayjs()] },
            { label: '昨天', value: [dayjs().add(-1, 'd'), dayjs()] },
            { label: '最近7天', value: [dayjs().add(-7, 'd'), dayjs()] },
            { label: '最近30天', value: [dayjs().add(-30, 'd'), dayjs()] },
        ]);

        // 搜索
        let toSearch=function(){
            page.page="1"
            getTabledata()
        }

        // 客服列表
         // 列表
         let kefuList=reactive({arr:[]})
         let getKefu=function(){
           
            proxy.$http.post('/admin/adminuserlist',{pagesize:500,admin_type_id:5},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    kefuList.arr=res.data.data.data
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getKefu()




        // 列表
         let getTabledata=function(){
            var date=""
            if(searchLlist.date){
                date=moment(searchLlist.date[0]+" 00:00:00").unix()+"-"+moment(searchLlist.date[1]+" 23:59:59").unix()
            }
            var call=""
            if(searchLlist.starttime||searchLlist.endtime){
                call=searchLlist.starttime+"~"+searchLlist.endtime
            }
            proxy.$http.post('/admin/phonecallloglist',{page:page.page,pagesize:page.pageSize,admin_user_id:searchLlist.seat,
            called_number:searchLlist.called,call_start_time:date,call_second:call},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    dataList.arr=res.data.data.data
                    page.total=res.data.data.total
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getTabledata()

       
        // 多选
        let selectedRowkeys=ref()
        let onSelectChange=function(selected) {
            selectedRowkeys.value = selected;
        }

         // 删除
        let delMore=function(){
            var id=selectedRowkeys.value.join(",")
            del(id)
        }
        let del=function(id){
            Modal.confirm({
                title: '你确定要删除吗？',
                icon: createVNode(ExclamationCircleOutlined),
            onOk() {
                proxy.$http.post('/admin/delscript',{ids:id},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            },
            onCancel() {
             console.log('Cancel');
            }
            })
            
        }



        // 分页
        let pageChange=function(current, size){
            page.pageSize=size
            page.page=current
            getTabledata()
        }
        let reload=function(){
            getTabledata()
        }
        return{
            reload,
            kefuList,
            changeFormdate,
            toSearch,searchLlist,rangePresets,
            onSelectChange,delMore,del,selectedRowkeys,
            columns,dataList,pageChange,page
        }
    }
}
</script>