
<template>
    <div >
        <!-- class="fpmenutabs" type="card" -->
        <!-- {{istab}} -->
        <a-tabs  class="fpmenutabs2" @change="changtab">
            <a-tab-pane v-if="tab1"  key="11" tab="话务充值记录">
                <div class="analysis-top">
                    <!-- <span>积分数：{{total1.data.data_no}}</span>
                    <a-divider type="vertical" />
                    <span>金额(元)：{{total1.data.points}}</span> -->
                </div>
                <!-- <a-divider style="margin:16px 0" /> -->
                 <div class="search">
                    <div class="left-option flexwrap2">
                        <div>
                            <a-range-picker :presets="rangePresets"  valueFormat="YYYY-MM-DD" v-model:value="searchLlist.date" />

                            <a-select
                                ref="select" v-model:value="searchLlist.admin_user_id" style="width: 120px;margin-left:14px"
                                placeholder="所属人"
                                allowClear
                            >
                                <a-select-option v-for="item in userList.arr" :value="item.id" :key="item.id">{{item.username}}</a-select-option>
                            </a-select>

                            <!-- <a-select ref="select" v-model:value="searchLlist.verify_state" style="width: 120px;margin-left:14px"
                                placeholder="操作"
                                allowClear
                            >
                                <a-select-option value="101">充值线索</a-select-option>
                                <a-select-option value="102">充值派单</a-select-option>
                                <a-select-option value='201' >减少线索</a-select-option>
                                <a-select-option value='202' >减少派单</a-select-option>
                                <a-select-option value='301' >消费线索</a-select-option>
                                <a-select-option value='302' >消费派单</a-select-option>
                            </a-select> -->

                            <!--  <a-select ref="select" v-model:value="searchLlist.jg" style="width: 120px;margin-left:14px"
                                placeholder="机构"
                                allowClear
                            >
                                <a-select-option v-for="item in institutionList.arr" :value="item.id" :key="item.id">{{item.title}}</a-select-option>
                            </a-select>
                            <a-select ref="select" v-model:value="searchLlist.verify_state" style="width: 120px;margin-left:14px"
                                placeholder="核实状态"
                                allowClear
                            >
                                <a-select-option value="0">待核实</a-select-option>
                                <a-select-option value="1">有效</a-select-option>
                                <a-select-option value="2">无效</a-select-option>
                            </a-select>

                            <a-input style="width:90px;margin-left:14px" placeholder="客户姓名" v-model:value="searchLlist.username" /> -->
                            <!-- <a-input style="width:120px;margin-left:14px" placeholder="线索号码" v-model:value="searchLlist.phone" /> -->
                            <!-- <a-select v-if="istab" ref="select" placeholder="线索状态" style="width: 120px;margin-left:14px"  v-model:value="searchLlist.clue_state"   allowClear>
                                <a-select-option v-for="item in cluestatuslist.arr" :value="item.id" :key="item.id">{{item.title}}</a-select-option>
                            </a-select> -->
                            <a-button @click="toSearch" type="primary">查询</a-button>
                        </div>
                        <a-button style="margin-top:0" class="reloadbtn" @click="reload1"><ReloadOutlined /> 刷新</a-button>
                    </div>
                    <div class="table-wrap">
                        <a-table  row-key="id" :pagination="false" :columns="columns" :data-source="dataList.arr" size="small"  >
                            
                            <template #bodyCell="{ column}">

                                <template v-if="column.dataIndex === 'type'">
                                    话务充值
                                </template>

                                
                            </template>
                        </a-table>
                        <a-pagination
                            v-model:pageSize="page.pageSize"
                            show-size-changer
                            :total="page.total"
                            @change="pageChange"
                            :show-total="total => `共 ${total} 条数据`"
                        />
                    </div>

                </div>
            </a-tab-pane>
            <a-tab-pane v-if="tab2" key="12" tab="线索积分充值">
                <div class="analysis-top">
                    <span style="margin-left:20px;">当前可用积分：{{total2.data.data_no}}</span>
                    <!-- <a-divider type="vertical" />
                    <span>金额(元)：{{total2.data.points}}</span> -->
                </div>
                <a-divider style="margin:16px 0" />
                 <div class="search">
                    <div class="left-option flexwrap2">
                        <div>
                            <a-range-picker :presets="rangePresets"  valueFormat="YYYY-MM-DD" v-model:value="searchLlist2.date" />

                            <a-select
                                ref="select" v-model:value="searchLlist2.admin_user_id" style="width: 120px;margin-left:14px"
                                placeholder="所属人"
                                allowClear
                            >
                                <a-select-option v-for="item in userList.arr" :value="item.id" :key="item.id">{{item.username}}</a-select-option>
                            </a-select>

                            <!-- <a-select ref="select" v-model:value="searchLlist2.verify_state" style="width: 120px;margin-left:14px"
                                placeholder="操作"
                                allowClear
                            >
                                <a-select-option value="101">充值线索</a-select-option>
                                <a-select-option value="102">充值派单</a-select-option>
                                <a-select-option value='201' >减少线索</a-select-option>
                                <a-select-option value='202' >减少派单</a-select-option>
                                <a-select-option value='301' >消费线索</a-select-option>
                                <a-select-option value='302' >消费派单</a-select-option>
                            </a-select> -->

                            <!--  <a-select ref="select" v-model:value="searchLlist.jg" style="width: 120px;margin-left:14px"
                                placeholder="机构"
                                allowClear
                            >
                                <a-select-option v-for="item in institutionList.arr" :value="item.id" :key="item.id">{{item.title}}</a-select-option>
                            </a-select>
                            <a-select ref="select" v-model:value="searchLlist.verify_state" style="width: 120px;margin-left:14px"
                                placeholder="核实状态"
                                allowClear
                            >
                                <a-select-option value="0">待核实</a-select-option>
                                <a-select-option value="1">有效</a-select-option>
                                <a-select-option value="2">无效</a-select-option>
                            </a-select>

                            <a-input style="width:90px;margin-left:14px" placeholder="客户姓名" v-model:value="searchLlist.username" /> -->
                            <!-- <a-input style="width:120px;margin-left:14px" placeholder="线索号码" v-model:value="searchLlist.phone" /> -->
                            <!-- <a-select v-if="istab" ref="select" placeholder="线索状态" style="width: 120px;margin-left:14px"  v-model:value="searchLlist.clue_state"   allowClear>
                                <a-select-option v-for="item in cluestatuslist.arr" :value="item.id" :key="item.id">{{item.title}}</a-select-option>
                            </a-select> -->
                            <a-button @click="toSearch2" type="primary">查询</a-button>
                        </div>
                        <a-button style="margin-top:0" class="reloadbtn" @click="reload2"><ReloadOutlined /> 刷新</a-button>
                    </div>
                    <div class="table-wrap">
                        <a-table  row-key="id" :pagination="false" :columns="columns2" :data-source="dataList2.arr" size="small"  >
                            
                            <template #bodyCell="{ column}">

                                <template v-if="column.dataIndex === 'type'">
                                    线索积分充值
                                </template>

                                
                            </template>
                        </a-table>
                        <a-pagination
                            v-model:pageSize="page2.pageSize"
                            show-size-changer
                            :total="page2.total"
                            @change="pageChange2"
                            :show-total="total => `共 ${total} 条数据`"
                        />
                    </div>

                </div>
            </a-tab-pane>

            <a-tab-pane v-if="tab3" key="13" tab="派单积分充值">
                <div class="analysis-top">
                    <span style="margin-left:20px;">当前可用积分：{{total3.data.data_no}}</span>
                    <!-- <a-divider type="vertical" />
                    <span>金额(元)：{{total3.data.points}}</span> -->
                </div>
                <a-divider style="margin:16px 0" />
                 <div class="search">
                    <div class="left-option flexwrap2">
                        <div>
                            <a-range-picker :presets="rangePresets"  valueFormat="YYYY-MM-DD" v-model:value="searchLlist3.date" />

                            <a-select
                                ref="select" v-model:value="searchLlist3.admin_user_id" style="width: 120px;margin-left:14px"
                                placeholder="所属人"
                                allowClear
                            >
                                <a-select-option v-for="item in userList.arr" :value="item.id" :key="item.id">{{item.username}}</a-select-option>
                            </a-select>
                            <a-button @click="toSearch3" type="primary">查询</a-button>
                        </div>
                        
                        <a-button style="margin-top:0" class="reloadbtn" @click="reload3"><ReloadOutlined /> 刷新</a-button>
                    </div>
                    <div class="table-wrap">
                        <!-- {{}} -->
                        <a-table  row-key="id" :pagination="false" :columns="columns3" :data-source="dataList3.arr" size="small"  >
                            
                            <template #bodyCell="{ column}">

                                <template v-if="column.dataIndex === 'type'">
                                    派单积分充值
                                </template>

                                
                            </template>
                        </a-table>
                        <a-pagination
                            v-model:pageSize="page3.pageSize"
                            show-size-changer
                            :total="page3.total"
                            @change="pageChange3"
                            :show-total="total => `共 ${total} 条数据`"
                        />
                    </div>

                </div>
            </a-tab-pane>
        </a-tabs>

        
    </div>
</template>

<script>
const columns = [
    { title: 'ID', dataIndex: 'id' ,width:90},
    { title: '充值账号', dataIndex: 'username_admin_user'},
    {title: '充值金额',dataIndex: 'balance'},
    {title: '充值类型',dataIndex: 'type'},
    {title: '时间',dataIndex: 'create_time'},
    


];
const columns2 = [
    { title: 'ID', dataIndex: 'id' ,width:90},
    { title: '充值账号', dataIndex: 'username_admin_user'},
    {title: '积分数',dataIndex: 'data_no'},
    {title: '积分单价',dataIndex: 'data_unit_price_each'},
    {title: '金额',dataIndex: 'points'},
    {title: '充值类型',dataIndex: 'type'},
    {title: '时间',dataIndex: 'create_time',width:170},
    

];

const columns3 = [
    { title: 'ID', dataIndex: 'id' ,width:90},
    { title: '充值账号', dataIndex: 'username_admin_user'},
    {title: '积分数',dataIndex: 'data_no'},
    
    {title: '积分单价',dataIndex: 'data_unit_price_each'},
    {title: '金额',dataIndex: 'points'},
    {title: '充值类型',dataIndex: 'type'},
    {title: '时间',dataIndex: 'create_time',width:170},
    

];


import { reactive ,ref} from '@vue/reactivity'
import { getCurrentInstance, computed } from 'vue'
import { message,Modal} from 'ant-design-vue';
import dayjs, { Dayjs } from 'dayjs';
import {useRouter} from "vue-router";
import moment from "moment";
import { formatDate } from "../../common/filter.js";
import "dayjs/locale/zh-cn";
export default {
    data(){
        return{
            noClick: true
        }
    },
    setup(){
         const changeFormdate = computed(() => {
            return (time) => {
                let date = new Date(time);
                return formatDate(date);
            };
        });

        let tab1=ref(false)
        let tab2=ref(false)
        let tab3=ref(false)
        let btnlist=JSON.parse(sessionStorage.getItem('btnlist'))
        for(var i in btnlist){
            if(btnlist[i].title=="通话充值记录"){
                tab1.value=true
            }
            if(btnlist[i].title=="线索积分充值"){
                tab2.value=true
            }
            if(btnlist[i].title=="派单积分充值"){
                tab3.value=true
            }
        }

        const router=useRouter()
        let searchLlist=reactive({date:"",admin_user_id:undefined})
        let searchLlist2=reactive({date:"",admin_user_id:undefined})
        let searchLlist3=reactive({date:"",admin_user_id:undefined})
        const { proxy } = getCurrentInstance()
        let dataList=reactive({arr:[]})
        let dataList2=reactive({arr:[]})
        let dataList3=reactive({arr:[]})
        const rangePresets = ref([
            { label: '今天', value: [dayjs().add(0, 'd'), dayjs()] },
            { label: '昨天', value: [dayjs().add(-1, 'd'), dayjs()] },
            { label: '最近7天', value: [dayjs().add(-7, 'd'), dayjs()] },
            { label: '最近30天', value: [dayjs().add(-30, 'd'), dayjs()] },
        ]);

        // let changtab=function(){
           
        //     getTabledata()
        // }

        // 客服列表
        let userList=reactive({arr:[]})
        let getUserlist=function(){
            proxy.$http.post('/admin/adminuserlist',{pagesize:500},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    userList.arr=res.data.data.data
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getUserlist()

        // 搜索
        let toSearch=function(){
            page.page="1"
            getTabledata()
        }
        let toSearch2=function(){
            page2.page="1"
            getTabledata2()
        }
        let toSearch3=function(){
            page3.page="1"
            getTabledata3()
        }

        let page=reactive({page:"",pageSize:10,total:20})//分页
        let total1=reactive({data:{}})
        let getTabledata=function(){
            var date=""
            if(searchLlist.date){
                date=moment(searchLlist.date[0]+" 00:00:00").unix()+"-"+moment(searchLlist.date[1]+" 23:59:59").unix()
            }
            
            proxy.$http.post('/admin/seatscostbalancerecharge',{page:page.page,pagesize:page.pageSize,create_time:date,
            admin_user_id:searchLlist.admin_user_id
            },{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    dataList.arr=res.data.data.data
                    total1.data=res.data.data.data_statistics
                    page.total=res.data.data.total
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getTabledata()

        let page2=reactive({page:"",pageSize:10,total:20})//分页
        let total2=reactive({data:{}})
        let getTabledata2=function(){
            var date=""
            if(searchLlist2.date){
                date=moment(searchLlist2.date[0]+" 00:00:00").unix()+"-"+moment(searchLlist2.date[1]+" 23:59:59").unix()
            }
            proxy.$http.post('/admin/pointscluecharge',{page:page2.page,pagesize:page2.pageSize,create_time:date,
            admin_user_id:searchLlist2.admin_user_id
            },{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    dataList2.arr=res.data.data.data
                    total2.data=res.data.data.data_statistics
                    page2.total=res.data.data.total
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getTabledata2()

        let page3=reactive({page:"",pageSize:10,total:20})//分页
        let total3=reactive({data:{}})
        let getTabledata3=function(){
            var date=""
            if(searchLlist3.date){
                date=moment(searchLlist3.date[0]+" 00:00:00").unix()+"-"+moment(searchLlist3.date[1]+" 23:59:59").unix()
            }
        
            proxy.$http.post('/admin/pointscustomcharge',{page:page3.page,pagesize:page3.pageSize,create_time:date,
            admin_user_id:searchLlist3.admin_user_id
            },{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    dataList3.arr=res.data.data.data
                    total3.data=res.data.data.data_statistics
                    page3.total=res.data.data.total
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getTabledata3()




        // 分页
        let pageChange=function(current, size){
            page.pageSize=size
            page.page=current
            getTabledata()
        }
        let pageChange2=function(current, size){
            page2.pageSize=size
            page2.page=current
            getTabledata2()
        }
        let pageChange3=function(current, size){
            page3.pageSize=size
            page3.page=current
            getTabledata3()
        }
        let reload1=function(){
            getTabledata()
        }
        let reload2=function(){
            getTabledata2()
        }
        let reload3=function(){
            getTabledata3()
        }
        return{
            reload1,reload2,reload3,
            tab1,tab2,tab3,
            total1,total2,total3,
            userList,changeFormdate,
            toSearch,toSearch2,toSearch3,
            pageChange,getTabledata,dataList,page,rangePresets,
            pageChange2,getTabledata2,dataList2,page2,
            pageChange3,getTabledata3,dataList3,page3,
            searchLlist,columns,searchLlist2,columns2,searchLlist3,columns3
        }
    }
}
</script>
