import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import login from '../views/login.vue'
import index from '../views/index.vue'
import person from '../views/person/person'
import nodelist from '../views/system/nodelist'
import rolelist from '../views/system/rolelist'
import loglist from '../views/system/loglist'
import system from '../views/system/system'
import limitslist from '../views/system/limitslist'
import cluetype from '../views/system/cluetype'
import cluestatus from '../views/system/cluestatus'
import basicset from '../views/system/basicset'

import dispatchlist from '../views/dispatch/dispatchlist'

import institution from '../views/institution/institution'
import account from '../views/institution/account'
import teluser from '../views/institution/teluser'
import userlist from '../views/useradmin/userlist'
import adminlist from '../views/useradmin/adminlist'

import tellist from '../views/telrecord/tellist'

import cluesea from '../views/clue/cluesea'
import customsea from '../views/clue/customsea'
import cluelist from '../views/clue/cluelist'
import custom from '../views/custom/custom'

import project from '../views/project/project'

import dataanalysis from '../views/analysis/dataanalysis'

import consumelist from '../views/finance/consumelist'
import integrallist from '../views/finance/integrallist'
import consumedetail from '../views/finance/consumedetail'

// ,redirect: {path: '/index'},
const routes = [
  { path: '/', name: 'home', component: HomeView ,
    children:[
      {path:'index',name:'index',component:index,meta:{title:"首页"}},
      {path:'consumedetail',name:'consumedetail',component:consumedetail,meta:{title:"消费明细"}},
      {path:'integrallist',name:'integrallist',component:integrallist,meta:{title:"充值记录"}},
      {path:'consumelist',name:'consumelist',component:consumelist,meta:{title:"消费记录"}},
      {path:'dataanalysis',name:'dataanalysis',component:dataanalysis,meta:{title:"数据分析"}},
      {path:'cluesea',name:'cluesea',component:cluesea,meta:{title:"线索列表"}},
      {path:'customsea',name:'customsea',component:customsea,meta:{title:"客户列表"}},
      {path:'project',name:'project',component:project,meta:{title:"项目管理"}},
      {path:'cluelist',name:'cluelist',component:cluelist,meta:{title:"线索列表"}},
      {path:'custom',name:'custom',component:custom,meta:{title:"客户管理"}},
      {path:'person',name:'person',component:person,meta:{title:"个人信息"}},
      {path:'nodelist',name:'nodelist',component:nodelist,meta:{title:"节点列表"}},
      {path:'rolelist',name:'rolelist',component:rolelist,meta:{title:"权限管理"}},
      {path:'limitslist',name:'limitslist',component:limitslist,meta:{title:"权限列表"}},
      {path:'dispatchlist',name:'dispatchlist',component:dispatchlist,meta:{title:"派单列表"}},
      {path:'institution',name:'institution',component:institution,meta:{title:"机构管理"}},
      {path:'account',name:'account',component:account,meta:{title:"机构账号"}},
      {path:'teluser',name:'teluser',component:teluser,meta:{title:"机构客服"}},
      {path:'adminlist',name:'adminlist',component:adminlist,meta:{title:"账号管理"}},
      {path:'userlist',name:'userlist',component:userlist,meta:{title:"客服管理"}},
      {path:'loglist',name:'loglist',component:loglist,meta:{title:"操作日志"}},
      {path:'system',name:'system',component:system,meta:{title:"网站设置"}},
      {path:'basicset',name:'basicset',component:basicset,meta:{title:"功能设置"}},
      {path:'cluetype',name:'cluetype',component:cluetype,meta:{title:"线索标签"}},
      {path:'cluestatus',name:'cluestatus',component:cluestatus,meta:{title:"线索状态"}},
      {path:'tellist',name:'tellist',component:tellist,meta:{title:"呼叫列表"}},
    ]
  },
  { path: '/login', name: 'login', component: login,meta:{title:"登录"} }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from , next) => {
  if(sessionStorage.getItem('netTitle')==null){
    sessionStorage.setItem('netTitle',"")
  }
  const title = (to.meta.title ? to.meta.title : '') + '-'+sessionStorage.getItem('netTitle')
  document.title = title
  // 判断是否含有登录标识
  const isLogin = sessionStorage.getItem('token')
  console.log("isLogin="+isLogin)
  // 想要跳转到 person页面 但是没有标识 就next 去登录
    if(!isLogin){
      if(to.name=="login"){
        next()
      }else{
        router.push('login')
      }
    }else{
      next()
    }
})

export default router
